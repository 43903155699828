/* MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
Adapted from: http://blog.heyimcat.com/universal-signup-form/ */

#mc_embed_signup form { 
	padding: 0;
	margin: 0;
}
.mc-field-group { 
	display: inline-block;
} 
/* positions input field horizontally */
#mc_embed_signup input.email {
	font-family: $font-open-sans;
	font-size: 15px;
	border: 1px solid $color-grey-light;
	border-radius: 3px;
	color: $color-text;
	background-color: #fff;
	box-sizing:border-box;
	height: 32px;
	padding: 0px 0.4em;
	display: inline-block;
	margin: 0;
	width: calc(100% - 113px);
	vertical-align:top;
}
#mc_embed_signup label {
	display: block;
	font-size: 1rem;
	padding-bottom: 0.25rem;
	font-weight: 600;
}
#mc_embed_signup .clear {
	display: inline-block;
} /* positions button horizontally in line with input */
#mc_embed_signup .button {
	font-size: 13px;
	border: none;
	border-radius: 3px;
	letter-spacing: .03em;
	color: #fff;
	background-color: $color-purple-dark;
	box-sizing: border-box;
	height: 32px;
	line-height: 32px;
	padding: 0 18px;
	display: inline-block;
	margin: 0;
	transition: all 0.23s ease-in-out 0s;
}
#mc_embed_signup .button:hover {
	background-color: darken($color-purple-dark, 10%);
	cursor: pointer;
}
#mc_embed_signup div#mce-responses {
	width: 100%;
}
#mc_embed_signup div.response {
	margin: 0;
	padding: .5em 0;
	font-weight: 600;
}
#mc_embed_signup #mce-error-response,
#mc_embed_signup #mce-success-response {
  display:none;
}
#mc_embed_signup label.error {
	display: block;
	float: none;
	width: auto;
	margin-left: 1.05em;
	text-align: left;
	padding: 0.5em 0;
}

@include media-breakpoint-down(sm) {
	#mc_embed_signup input.email {
		width: 100%; 
		margin-bottom: 5px;
	}
	#mc_embed_signup .clear {
		display: block;
		width: 100% 
	}
	#mc_embed_signup .button {
		width: 100%;
		margin:0;
	}
}

