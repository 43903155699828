/* Typography */

body {
	@include font-open-sans();
	font-size: $base-font-size;
	color: $color-text;
}

h1, h2, h3 {
	@include font-merriweather();
}

b, strong {
	@include font-open-sans(600);
}

a {
	color: $color-text-link;
	&:hover,
	&:active {
		color: $color-text-link;
	}
}

.large {
	font-size: (24rem / 16);
}

.small {
	font-size: (12rem / 16);
}

.lead-in {
	font-size: (24rem / 16);
	margin-bottom: 1em;
	padding-left: 12%;
	padding-right: 12%;
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
	}
}

.align-center {
	text-align: center;
}