@mixin caption {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 20px;
    padding: 0 1em; 
    z-index: 1;
}