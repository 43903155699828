/* Footer */

footer {
	background: $color-grey;
	//background: linear-gradient(to bottom, darken($color-grey, 10%) 0%, $color-grey 100%);
	background: $color-grey-dark;
	color: #fff;
	//text-align: center;
	padding: $grid-padding-lg 0;
	
	/*
	&::before {
		content: '';
		display: inline-block;
		background: url('../images/flourish-bottom.svg') center center no-repeat;
		background-size: contain;
		width: 250px;
		max-width: 80%;
		height: 60px;
		opacity: 0.5;
	}
	*/
	
	a {
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	
	.footer-left {
		text-align: left;
		font-size: 12px;
	}
	.footer-middle {
		text-align: center;
		nav a {
			display: block;
		}
	}
	.footer-right {
		text-align: right;
		vertical-align: top;
		h3 {
			font-size: (14rem / 16);
		}
		.social {
			position: relative;
			font-size: 24px;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.logo {
		height: 2rem;
		margin-bottom: 0.75rem;
	}
	
	@include media-breakpoint-down(sm) {
		padding: $grid-padding-sm 0;

		.footer-left,
		.footer-middle,
		.footer-right {
			text-align: center;
			font-size: 12px;
		}
		.footer-middle {
			nav {
				margin-top: 2em;
				a {
					display: inline-block;
					font-size: 16px;
					padding: 8px 4px;
					margin: 0 4px;
				}
			}
		}
		.footer-right {
			padding-top: $grid-padding-sm;
			.social {
				font-size: 36px;
				padding: 2px 4px;
			}
		}
	}

}