$base-font-size: 16px !default;

/**
 * grid
 */

$screen-xs:                  480px;
$screen-sm:                  768px;
$screen-md:                  992px;
$screen-lg:                  1200px;

/**
 * responsive
 */

$breakpoints: (
	'xs':	$screen-xs,
	'sm':	$screen-sm,
	'md':	$screen-md,
	'lg':	$screen-lg
);

/**
 * Grid 
 */

$width-container-lg: 1140px;
$width-container-md: 960px;
$width-container-sm: 730px;

$grid-padding-lg: 35px;
$grid-padding-sm: 20px;

$page-section-spacing: (110rem / 16);

/**
 * easing
 */

$ease-1:                     0.3s linear;

/**
 * Sizes
 */
