/* Fonts */

@mixin font-alliased() {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css?family=Merriweather:400,700|Open+Sans:300,400,600');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Open Sans

$font-open-sans: 'Open Sans','Helvetica Neue',Arial,Helvetica,Verdana,sans-serif;

.open-sans {
	font-family: $font-open-sans;
	font-weight: 400;
}

@mixin font-open-sans($weight: 400, $style: normal) {
	font-family: $font-open-sans;
	font-style: $style;
	font-weight: $weight;
}

// Amarante

$font-merriweather: 'Merriweather', Arial, Helvetica, sans-serif;

.merriweather {
	font-family: $font-merriweather;
	font-weight: 700;
}

@mixin font-merriweather($weight: 700) {
	font-family: $font-merriweather;
	font-weight: $weight;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}



.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
  
	@include font-alliased();
  
	/* Support for IE. */
	font-feature-settings: 'liga';
  }