.people-list {

    .person {
        .person-image-frame {
            max-width: 200px;
            .person-image {
                position: relative;
                width: 100%;
                padding-top: 100%;
                background: center center no-repeat;
                background-size: cover;
                border-radius: 50%;
            }
        }
        .person-content {
            margin-bottom: $page-section-spacing;
        }
    }

    @include media-breakpoint-down(sm) {
        .person {
            .person-image-frame {
                margin: 0 auto 15px auto;
            }
            .person-content {
                margin-bottom: ($page-section-spacing / 2);
                h3 {
                    text-align: center;
                }
            }
        }
    }
}