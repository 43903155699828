/* Subscribe form */

.page-section-subscribe {
	background: $color-purple-dark;
	color: $color-white;
	padding: $grid-padding-lg 0;

	#main  &.last-on-page {
		margin-bottom: 0;
	}
	#mc_embed_signup input.email {
		border-color: $color-white;
	}
	#mc_embed_signup .button {
		@include button-white;
		line-height: 28px;
		padding: 0 1rem;
    }
    .col-md-6 p:last-of-type {
        margin-bottom: 0;
    }
	@include media-breakpoint-down(md) {
		text-align: center;
	}
}