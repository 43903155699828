.blog-page-archive {

    .blog-category-selector {
        padding: $grid-padding-lg 0;
        background: $color-purple-dark;
        color: $color-white;
        margin-bottom: $grid-padding-lg;
        text-align: center;

        .cat {
            display: inline-block;
            position: relative;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $color-white;
            text-decoration: none;
            margin: 0 1rem;

            &::after {
                content: '';
                display: block;
                position: absolute;
                background: $color-white;
                height: 2px;
                width: 0px;
                bottom: -0.5rem;
                left: 50%;
                transform: translateX(-50%);
                transition: 0.3s;
            }
            &.selected::after,
            &:hover::after {
                text-decoration: none;
                width: 100%;
                max-width: 100%;
            }

            .cat-count {
                display: inline-block;
                min-width: 1rem;
                line-height: 1rem;
                background: $color-purple;
                font-size: 12px;
                text-align: center;
                border-radius: 0.5rem;
            }
        }
    }

    .blog-list {
        .blog-post {
            box-shadow: 0 0 8px rgba(0,0,0,0.1);
            margin-bottom: $grid-padding-lg;

            .blog-image {
                position: relative;
                background: center center no-repeat;
                background-size: cover;
                width: 100%;
                padding-top: 75%; 
                .caption {
                    @include caption;
                }
            }

            .blog-content {
                padding: 15px;
                h3 {
                    font-size: 1.25rem;
                }
            }

            .meta {
                color: $color-purple;
                text-transform: uppercase;
                font-size: (14rem / 16);
                line-height: 24px;
                font-weight: 600;
            }
        }
    }

}