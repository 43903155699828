/* Content */

#main {
	//margin-bottom: $grid-padding-lg;

	.page-section {
		margin-bottom: $page-section-spacing;
		@include media-breakpoint-down(sm) {
			margin-bottom: ($page-section-spacing / 2);
		}
	}

	.section-title {
		text-align: center;
		margin-bottom: $grid-padding-lg;
		@include media-breakpoint-down(sm) {
			margin-bottom: $grid-padding-sm;
		}
	}
}