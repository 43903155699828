

.image-wrap {
	position: relative;
	margin-bottom: 1.8em;
	img {
		max-width: 100%;
		display: block;
	}
	.caption {
		@include caption;
		/*
		position: absolute;
		bottom: 0;
		right: 0;
		background: rgba(0,0,0,0.4);
		color: #fff;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 24px;
		padding: 0 1em; 
		z-index: 1;
		*/
	}
}







