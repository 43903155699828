/* Banner */

.banner-container {
	position: relative;
	width: 100%;
	height: 30vw;
	overflow: hidden;
	margin-bottom: $page-section-spacing;
	@include media-breakpoint-down(sm) {
		margin-bottom: ($page-section-spacing / 2);
	}

	.banner-back {
		position: absolute;
		background: center center no-repeat;
		background-size: cover;
		width: 100%;
		height: 40vw;
		z-index: 1;
	}
	.banner-front {
		position: absolute;
		top: 75%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		h1 {
			color: $color-white;
			text-shadow: 4px 4px 8px rgba(0,0,0,0.4);
			font-size: 5rem;
		}
	}

	.banner-caption {
		@include caption;
	}

	@include media-breakpoint-down(sm) {
		height: 50vw;
		.banner-back {
			height: 50vw;
		}
		.banner-front h1 {
			font-size: 4rem;
		}
	}
	
	@include media-breakpoint-down(xs) {
		.banner-front h1 {
			font-size: 3rem;
		}
	}
}

.banner {
	position: relative;
	background: $color-purple-dark center center no-repeat;
	background-size: cover;
	width: 100%;
	height: 40vw;
	margin-bottom: $page-section-spacing;
	text-align: center;
	z-index: 1;

	h1 {
		color: $color-white;
		text-shadow: 4px 4px 8px rgba(0,0,0,0.4);
		font-size: 5rem;
	}

	&.banner-narrow {
		height: 5.5rem;
		h1 {
			font-size: 3rem;
			padding-top: 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: ($page-section-spacing / 2);
	}

	.banner-caption {
		@include caption;
	}
	
	.logo {
		position: absolute;
		//max-width: 80%;
		width: 30%;
		top: 50%;
		left: 50%;
		transform: translate(-48%, 0);
	}
	
	@include media-breakpoint-down(sm) {
		.logo {
			width: 480px;
		}
	}
	
	@include media-breakpoint-down(xs) {
		.logo {
			width: 260px;
		}
	}

}