.page-section-form {

    .form-social-links {
        a {
            &::before {
                font-size: 3rem;
                color: $color-purple;
                vertical-align: middle;
            }
            &:hover {
                text-decoration: none;
                &::before {
                    color: $color-purple-dark;
                }
            }
        }
    }

}

.gform_wrapper {
    .gfield {
        textarea,
        input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
            border: 1px solid $color-grey-light;
            border-radius: 3px;
        }
    }
}