.larp-list {
    .larp {
        box-shadow: 0 0 8px rgba(0,0,0,0.1);
        margin-bottom: $grid-padding-lg;
        @include clearfix();

        .larp-image {
            position: relative;
            background: center center no-repeat;
            background-size: cover;
            width: 33.33%;
            padding-top: (33.33% * 0.75); 
            float: left;
            .caption {
                @include caption;
            }
        }
        .larp-content {
            margin-left: calc(33.33% + 30px);
            padding: 15px;
        }
        .larp-session {
            color: $color-purple-dark;
            text-transform: uppercase;
            font-size: (14rem / 16);
            line-height: 24px;
            font-weight: 600;
            margin-top: 1em;
            margin-bottom: 1em;

            .session-info {
                display: inline-block;
                position: relative;
                padding-left: 30px;
                margin-right: 18px;

                .material-icons {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $color-purple;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
            
        }
    }

    @include media-breakpoint-down(sm) {
        .larp {
            .larp-image {
                width: 100%;
                padding-top: 75%;
                float: none; 
            }
            .larp-content {
                margin-left: 0;
                text-align: center;
            }
        }   
    }

}
