// Blockquote

.fancy-blockquote {
    text-align: center;
	margin: 0 2em;
	
	&::before,
	&::after {
        content: '';
        display: block;
        position: relative;
		height: 30px;
		background: url('../images/flourish-sidebar-top.svg') center center no-repeat;
		background-size: contain;
		margin: 1em 0;
		opacity: 0.5;
	}
	
	&::after {
		transform: rotate(180deg);
    }
}