.page-section-testimonials {
    .container-fluid .col-12 {
        padding-left: 0;
        padding-right: 0;
    }

    .slider-container {
        .carousel-item {
			position: relative;

			.testimonial-content {
				position: absolute;
				top: 50%;
				width: 50%;
				padding: 0;
				color: $color-white;
				text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
				transform: translateY(-50%);
			}
			&.text-pos-left .testimonial-content {
				left: $grid-padding-lg*3;
			}
			&.text-pos-right .testimonial-content {
				right: $grid-padding-lg*3;
            }
            &.text-pos-full-width .testimonial-content {
                left: $grid-padding-lg*3;
                right: $grid-padding-lg*3;
                width: auto;
			}
			&.text-color-dark .testimonial-content {
				color: $color-text;
				text-shadow: 2px 2px 4px rgba(255,255,255,0.3);
			}

			.testimonial-quote {
				font-size: 1.75rem;
				
				p:first-of-type {
					text-indent: -0.9em;
					&::before {
						content: open-quote " ";
						@include font-merriweather(700);
					}
				}
				p:last-of-type::after {
					content: " " close-quote;
					@include font-merriweather(700);
				}
			}
			.testimonial-citation {
				font-style: italic;
				&::before {
					content: " - ";
				}
			}
			
            .caption {
				@include caption;
            }
        }
	}

	.carousel-control-next, 
	.carousel-control-prev {
		width: $grid-padding-lg * 2;
	}

	@include media-breakpoint-down(md) {
		.slider-container {
			.carousel-item {
				&.text-pos-left .testimonial-content {
					left: $grid-padding-lg*2;
				}
				&.text-pos-right .testimonial-content {
					right: $grid-padding-lg*2;
                }
                &.text-pos-full-width .testimonial-content {
                    left: $grid-padding-lg*2;
                    right: $grid-padding-lg*2;
                    width: auto;
                }
				.testimonial-quote {
					font-size: 1.2rem;
				}
			}
		}
	}
	
	@include media-breakpoint-down(sm) {
		.slider-container {
			.carousel-item {
				overflow: hidden;
				.testimonial-background {
					width: 120% !important;
					transform: translateX(-10%);
				}
				&.text-pos-left .testimonial-content {
					left: $grid-padding-sm*1.5;
					width: 60%;
				}
				&.text-pos-right .testimonial-content {
					right: $grid-padding-sm*1.5;
					width: 60%;
                }
                &.text-pos-full-width .testimonial-content {
                    left: $grid-padding-sm*1.5;
                    right: $grid-padding-sm*1.5;
                    width: auto;
                }
				.testimonial-quote {
					font-size: 1rem;
				}
				.testimonial-citation {
					font-size: 12px;
				}
			}
		}
		// Full bleed
		.container {
			padding-left: 0;
			padding-right: 0;
			.row {
				margin-left: 0;
				margin-right: 0;
				.col-12 {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.carousel-control-next, 
		.carousel-control-prev {
			display: none;
			width: $grid-padding-sm*1.5;
		}
		.carousel-indicators {
			margin-bottom: 0;
			li {
				width: 3px; 
			}
		}
	}

	@include media-breakpoint-down(xs) {
		.slider-container {
			.carousel-item {
				.testimonial-citation {
					display: none;
				}
			}
		}
	}
}