@include media-breakpoint-down(sm) {
    #main-header {
        nav {
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $color-text;
            
            ul.nav-main {
				li {
                    display: block;
                    font-size: (20rem / 16);
                    line-height: (40 / 20);
                    margin: 15px 0;
                    padding-left: (30rem / 16);

                    a {
                        position: relative;
                        display: inline-block;
                        color: $color-text;
                        text-decoration: none;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            background: $color-text;
                            height: 2px;
                            width: 0px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    &.current-menu-item a::after {
                        text-decoration: none;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}