.page-section-slider {
    .container-fluid .col-12 {
        padding-left: 0;
        padding-right: 0;
    }

    .slider-container {
        .carousel-item {
            .caption {
                @include caption;
            }
        }
    }
}