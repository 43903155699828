/* Colors */

$color-white: #ffffff;
$color-grey: #666666;
$color-grey-light: #dddddd;
$color-grey-dark: #333333;
$color-black: #000000;

$color-pink: #f5ab99;
$color-flesh: #feb47b;
$color-coral: #ff7e5f;
$color-purple: #765285;
$color-purple-dark: #351c4d;

$color-blueprint: #344c6b;
$color-goodsociety: #b67451;

$color-text: $color-grey-dark;
$color-text-link: $color-purple-dark;

$gradient: linear-gradient(to bottom, $color-grey-dark 0%, $color-grey 100%);