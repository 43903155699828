@include media-breakpoint-up(sm) {
    #main-header {
        nav {
            text-align: right;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $color-text;

            ul.nav-main {
                list-style: none;
                margin: 0;
                padding: 0;
        
                li {
                    position: relative;
                    display: inline-block;
                    margin: 0 18px;
        
                    &:first-of-type { margin-left: 0; }
                    &:last-of-type { margin-right: 0; }
            
                    a {
                        position: relative;
                        display: inline-block;
                        color: $color-text;
                        line-height: 50px;
                        margin: 0;
                        padding: 0; 
                        text-decoration: none;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            background: $color-purple-dark;
                            height: 2px;
                            width: 0px;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: 0.3s;
                        }
                        
                        &:hover {
                            text-decoration: none;
                            color: $color-text;
                        }
                    }

                    &.current-menu-item a::after,
                    & a:hover::after {
                        text-decoration: none;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}