.larp-page {

    .banner {
        .container{
            position: relative;
            height: 100%;
        }
        .larp-logo {
            position: absolute;
            top: 50%;
            max-width: 33.33%;
            &.center {
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.left {
                left: $grid-padding-lg;
                transform: translate(0, -50%);
            }
            &.right {
                right: $grid-padding-lg;
                transform: translate(0, -50%);
            }
        }
    }
    &.has-sessions .banner {
        margin-bottom: 0;
    }
    .banner-container {
        margin-bottom: 0;
    }

    .social-links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: wrap;
        margin-top: 1rem;
        .social-link-label {
            display: block;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            width: 100%;
            text-align: center;
        }
        .social-link {
            font-size: 2.5em;
            margin: 0 0.25rem;
            display: inline-block;
            height: 2.5em;
            i {
                line-height: 0;
                margin: 0;
                display: inline-block;
            }
        }
    }

    .larp-sessions {
        margin-bottom: $page-section-spacing;
        &.last-on-page {
            margin-bottom: 0;
        }

        .larp-session {
            padding: $grid-padding-lg 0 0 0;
            background: $color-purple-dark;
            color: $color-white;

            &:last-of-type {
                padding-bottom: $grid-padding-lg;
            }

            h3 {
                margin-bottom: 0;
            }

            .larp-session-item {
                position: relative;
                padding-left: 3rem;
                line-height: 2rem;
                font-weight: 600;
                font-size: (18rem / 16);

                .material-icons {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 2rem;
                    //color: $color-purple;
                    color: rgba($color-white, 0.5);
                }

                &.address-toggle {
                    position: relative;
                    cursor: pointer;
                    &:hover .address {
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                .address {
                    position: absolute;
                    top: 1.8rem;
                    left: 0;
                    width: 100%;
                    min-width: 200px;
                    padding: 0.5rem 0 1rem 3rem;
                    background: $color-purple-dark;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: normal;
                    opacity: 0;
                    pointer-events: none;
                    transition: 0.3s;
                    z-index: 1;
                }
            }

            .col-buttton {
                text-align: right;
            }

            @include media-breakpoint-down(sm) {
                h3,
                .larp-session-item {
                    margin-bottom: 1rem;

                    .address {
                        position: static;
                        opacity: 1;
                        padding: 0;
                        margin-bottom: 1rem;
                    }
                }
                
                .col-buttton { text-align: left; }
            }

        }
    }

    .dd {
        text-align: center;
        margin-bottom: $grid-padding-lg;
        .dd-cover {
            display: inline-block;
            border: 1px solid rgba(0,0,0,0.1);
            box-shadow: 4px 4px 6px rgba(0,0,0,0.1);
            margin-bottom: 0.5rem;
        }
        a {
            font-weight: 600;
            &:hover {
                color: $color-purple-dark;
            }
        }
    }

}