table {
    &.styled {
        border-collapse: collapse;
        td {
            padding: 0.5rem;
            border-bottom: 1px solid rgba(black, 0.25);
        }
        tr:first-of-type td {
            border-top: 1px solid rgba(black, 0.25);
        }
    }

    &.zebra {
        tr:nth-child(odd) {
            td {
                background: rgba($purple, 0.05);
            }
        }
    }
}