/* Header */

$height-main-header: 120px;
$height-sm-main-header: 75px;

#main-header {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: $color-white;
	height: $height-main-header;
	box-shadow: 0 2px 8px rgba(0,0,0,0.2);
	transition: 0.3s;
	z-index: $zindex-fixed;
	
	
	.header-logo {
		width: 163px;
		height: 60px;
		margin-top: (($height-main-header - 60) / 2);
		border: none;
		transition: 0.3s;
	}

	.header-right {
		text-align: right;
		padding-top: (($height-main-header - 50) / 2);
		transition: 0.3s;
	}
	
	.header-toggle {
		display: none;
	}

	.scrolled & {
		height: $height-sm-main-header;

		.header-logo {
			width: 163px * 0.75;
			height: 60px * 0.75;
			margin-top: (($height-sm-main-header - 45) / 2);
		}

		.header-right {
			padding-top: (($height-sm-main-header - 50) / 2);
		}
	}
	
	@include media-breakpoint-down(sm) {
		height: $height-sm-main-header;
		> .container {
			max-width: calc(100vw - 8px);
		}
		
		.header-logo {
			width: 130px;
			height: 48px;
			margin-top: ($height-sm-main-header - 48) / 2;
		}
		
		.header-right {
			display: none;
		}
		
		.header-toggle {
			display: block;
			text-align: right;
			padding-top: ($height-sm-main-header - 48) / 2;
			a {
				position: relative;
    			display: inline-block;
    			width: 48px;
    			height: 48px;
				border-radius: 50%;
    			transition: 0.3s;
				background: $color-purple-dark;
				span {
					position: absolute;
					display: block;
					height: 3px;
					width: 22px;
					border-radius: 3px;
					background-color: $color-white;
					transition: 0.3s;
					transform-origin: center center;
					top: 0;
					left: (-22px + 48)/2;
				}
				span:nth-child(3n) { transform: translateY(14px); }
				span:nth-child(3n+1) { transform: translateY(22px); }
				span:nth-child(3n+2) { transform: translateY(30px); }
			}
		}
	}
}

// Header spacer - use with fixed header
.header-spacer {
	height: $height-main-header;
	@include media-breakpoint-down(sm) {
		height: $height-sm-main-header;
	}
}

@include media-breakpoint-down(md) {
	.menu-open #main-header {

		.header-toggle a {
			span:nth-child(3n) { transform: translateY(22px) rotate(-45deg); }
			span:nth-child(3n+1) { transform: translateY(22px) rotate(45deg);}
			span:nth-child(3n+2) { transform: translateY(22px) rotate(-45deg);}
		}

		.header-right {
			position: fixed;
			display: block;
			top: $height-sm-main-header;
			height: calc(100vh - #{$height-sm-main-header});
			left: 0;
			right: 0;
			background-color: $color-white;
			text-align: left;
			padding: 0;
			z-index: $zindex-fixed;
			
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
		}
	}
	
	.menu-open {
		overflow: hidden;
	}
}

// Space for Wordpress admin

.admin-bar #main-header {
	top: 32px;
	@include media-breakpoint-down(md) {
		top: 46px;
	}
}

@include media-breakpoint-down(md) {
	.admin-bar.menu-open #main-header {
		.header-right {
			top: $height-sm-main-header + 46px;
			height: calc(100vh - $height-sm-main-header - 46px);
		}
	}
}