@mixin button {
    display: inline-block;
    line-height: 2rem;
    padding: 0 2rem;
    border-radius: 1.1rem;
    border: 2px solid $color-purple-dark;
    color: $color-purple-dark;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        color: $color-white;
        background-color: $color-purple-dark;
        text-decoration: none;
    }
}

@mixin button-white {
    @include button;
    border-color: $color-white;
    color: $color-white;

    &:hover {
        color: $color-purple-dark;
        background-color:$color-white;
    }
}

.button {
    @include button;
}

.button-white {
    @include button-white;
}


/*
.button,
.button-white {
    display: inline-block;
    line-height: 2rem;
    padding: 0 2rem;
    border-radius: 1.1rem;
    border: 2px solid $color-purple-dark;
    color: $color-purple-dark;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        color: $color-white;
        background-color: $color-purple-dark;
        text-decoration: none;
    }
}

.button-white {
    border-color: $color-white;
    color: $color-white;

    &:hover {
        color: $color-purple-dark;
        background-color:$color-white;
    }
}
*/